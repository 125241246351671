import * as React from 'react';
import { Creative } from 'model-types';
import { bannerPlacementChoices } from './field-choices';
import { FC, useEffect, useState } from 'react';
import {
  SelectArrayInput,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
  ImageInput,
  ReferenceArrayInput,
  required,
  useRedirect,
} from 'react-admin';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { countryChoices } from '../countries';
import { platformChoices } from './field-choices';
import { httpClient } from '../httpClient';
import CreativePreviewField from './CreativePreviewField';
import { set } from 'lodash';
import { useNotify } from 'ra-core';

const useStyles = makeStyles((theme) => ({
  form: {
    position: 'relative',
  },
  container: {
    width: '50%',
  },
  field: {
    width: '100%',
  },
  booleanField: {
    float: 'left',
  },
  placeholders: {
    fontSize: '12px',
    paddingLeft: '2px',
  },
  removeButton: {
    display: 'inline-block',
    position: 'relative',
    float: 'left',
    '& button': {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      minWidth: theme.spacing(2),
      opacity: 0,
    },
    '&:hover button': {
      opacity: 1,
      zIndex: 1,
    },
  },
  loader: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translate(-16px, -16px)',
    backgroundColor: '#00000087',
    color: 'white',
  },
}));

interface CreativeProps {
  record?: Creative;
}

const BannerForm: FC<CreativeProps> = ({ record, ...rest }) => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const [transferGroups, setTransferGroups] = useState([]);
  const [sites, setSites] = useState([]);
  const [transferGroupsChoices, setTransferGroupsChoices] = useState([]);
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = async (data: Creative) => {
    setIsLoading(true);

    try {
      if (data.id) {
        await httpClient.put(`/ads-banners/${data.id}`, { ...data });
      } else {
        await httpClient.post('/ads-banners', { ...data });
      }
      redirect('/ads-banners/');
    } catch (err) {
      notify(`Data not saved. Error msg: ${err}`, 'warning');
    }
  };

  set(record ?? {}, 'name', fileName);

  useEffect(getSites, [transferGroups]);
  useEffect(() => getTransferGroups(), []);

  function getSites() {
    if (transferGroups.length === 0) {
      return;
    }

    httpClient
      .get('/site/choices', {
        params: {
          transfer_groups: transferGroups,
        },
      })
      .then((response) => {
        setSites(response.data);
      });
  }

  function getTransferGroups() {
    httpClient
      .get('/user-transfers/groups/choices/for-banners')
      .then((response) => {
        setTransferGroupsChoices(response.data);
      });
  }

  function handleChangeGroup(event: any) {
    let value = event.target.value;
    setTransferGroups(value);
  }

  function handleChangeCreative(event: any) {
    const preview = document.getElementById('editPreview');

    if (event instanceof File) {
      setFileName(event.name);

      if (preview) {
        preview.style.display = 'none';
        return;
      }
    }

    if (null === event && preview) {
      preview.style.display = '';
    }
  }

  return (
    <SimpleForm
      {...rest}
      record={record}
      redirect={false}
      save={submitForm}
      className={classes.form}
    >
      {isLoading && (
        <div className={classes.loader}>
          <CircularProgress color="inherit" />
        </div>
      )}
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <BooleanInput source="is_active" defaultValue={true} />
        </Grid>
        <Grid item xs={6}>
          <SelectArrayInput
            source="countries"
            choices={countryChoices}
            className={classes.field}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Placement"
            validate={required()}
            source="placement"
            choices={bannerPlacementChoices}
            className={classes.field}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectArrayInput
            label="Platforms"
            source="platforms"
            choices={platformChoices}
            className={classes.field}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectArrayInput
            optionText="name"
            label="Transfer groups"
            source="group_ids"
            validate={required()}
            onChange={(e: any) => {
              handleChangeGroup(e);
            }}
            choices={transferGroupsChoices}
            className={classes.field}
          />
        </Grid>
        <Grid item xs={6}>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (record?.id) {
                setTransferGroups(formData.group_ids ?? []);
              }

              return (
                <SelectArrayInput
                  source="sites"
                  choices={sites}
                  className={classes.field}
                  {...rest}
                />
              );
            }}
          </FormDataConsumer>
        </Grid>
        <Grid item xs={12}>
          <ImageInput
            source="pictures[creative]"
            label="Creative"
            accept=".png,.jpeg,.gif,.jpg,.mp4"
            placeholder={
              <p>Drop your file or click to browse (mp4, gif, png, jpeg)</p>
            }
            onChange={(e: any) => {
              handleChangeCreative(e);
            }}
            className={classes.removeButton}
          >
            <CreativePreviewField source="src" />
          </ImageInput>
          <CreativePreviewField id="editPreview" source="creative" />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Comment"
            source="comment"
            className={classes.field}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
export default BannerForm;
