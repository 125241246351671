export type UserPhoto = {
  id: number;
  is_main: boolean;
  big_url: string;
  profile_url: string;
};

export type UserVideo = {
  id: number;
  type: number;
  url: string;
  preview_url: string;
};

export type City = {
  id: number;
  name: string;
  region: string;
  country: string;
};

export type UserShort = {
  id: number;
  username: string;
  age: number;
  gender: string;
  photo_count: number;
  city: City;
};

export type User = {
  id: number;
  email: string;
  site_id: number;
  age: number;
  username: string;
  moderation_status: string;
  is_deleted: boolean;
  gender: string;
  parent_id: number;
  profile_type: string;
  photo_count: number;
  thumbnail: string;
  created_at: string;
  city: string;
  host: string;
  country: string;
  country_name: string;
  is_premium: boolean;
  is_platinum: boolean;
  is_vip: boolean;
  is_chb: boolean;
  credits: number;
  related_user_ids: number[];
};

export type UserLong = User & {
  has_regular_subscription: boolean;
  has_video_upsale_subscription: boolean;
  has_vip_subscription: boolean;
  has_credit_subscription: boolean;
  has_credit_upsale_subscription: boolean;
  has_video_promote_subscription: boolean;
  has_extra_platinum_upsale_subscription: boolean;
  do_not_sell: boolean;
};

export type UserAdditionalInfo = {
  id: number;
  host: string;
  country_name: string;
};

export type WebpushTpl = {
  id: number;
  type: number;
  title_text_source_id: number;
  body_text_source_id: number;
  // iconUrl: string;
  // imageUrl: string;
  link: string;
  is_active: boolean;
};

export type Partner = {
  id: number;
  name: string;
  region: string;
  country_code: string;
  platform: string;
  manager: string;
  partner_group: string;
  traffic_type: string;
  cost_model: string;
  direction: string;
  partner_tag: string;
  spammer: string;
  network_id: number;
  autoreg: boolean;
  is_shave_invalid_email: boolean;
  postback_event_configs: PostbackEventConfig[];
  subsource_groups: SubsourceGroup[];
  api_buying_config: ApiBuyingConfig;
  tds_path_configs: PartnerTdsPathConfig[];
  first_subsource: Subsource | null;
};

export type Subsource = {
  [subsource_id: number]: any;
  [name: string]: any;
  name2: string;
  name3: string;
  name4: string;
  partner_id: number;
  ts: string;
  level: number;
  subsource_group_id: number;
};

export type PartnerNetwork = {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
};

export type PostbackEventConfig = {
  id: number;
  is_enabled: boolean;
  subsource_id: number | null;
  partner_id: number;
  event_type: number;
  enabled_percentage: number;
  percentage_apply_offset: number;
  payout: number;
  url_pattern: string;
  postback_event_config_timings: PostbackEventConfigTiming[];
  partner_config_emails: PartnerConfigEmail[];
};

export type SubsourceGroup = {
  id: number;
  name: string;
  partner_id: number;
  event_type: number;
  enabled_percentage: number;
  percentage_apply_offset: number;
  payout: number;
  url_pattern: string;
  partner_config_emails: PartnerConfigEmail[];
  subsource_rules: SubsourceRule[];
  first_subsource: Subsource | null;
};

export type SubsourceRule = {
  id: number;
  field: string;
  operator: string;
  value: string;
  subsource_group_id: number;
};

export type PostbackEventConfigTiming = {
  id: number;
  from_hour: number;
  from_minute: number;
  to_hour: number;
  to_minute: number;
  enabled_percentage: number;
};

export type PartnerConfigEmail = {
  id: number;
  domain: string;
  enabled_percentage: number;
};

export type ApiBuyingConfig = {
  api_key: string;
  is_reg_enabled: boolean;
  is_visit_enabled: boolean;
};
export type PartnerTdsPathConfig = {
  id: number;
  partner_id: boolean;
  subsource_id: number;
  tds_path_id: number;
  is_api_blocked: boolean;
};

export type TransferGroup = {
  id: number;
  name: string;
  qualification_reasons: number[];
  site_group_ids: number[];
};
export type TransferBrand = {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
};
export type TransferGeoSegment = {
  id: number;
  name: string;
  countries: number[];
  created_at: string;
  updated_at: string;
};
export type AffiliateNetwork = {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
};
export type TransferOfferFilter = {
  positive: number[];
  negative: number[];
  all: boolean | null;
};
export type TransferOffer = {
  id: number;
  is_active: boolean;
  campaign_id: string;
  weight: number;
  group_id: number;
  url: string;
  for_site_ids: number[];
  external_offer_id: string;
  is_remnant: boolean;
  premium_emails: boolean;
  brand_id: number;
  cost_model: string;
  click_cost: number | null;
  hourly_cap: number | null;
  daily_cap: number | null;
  total_cap: number | null;
  cap_timezone: number;
  affiliate_network_id: number;
  platform: number[] | null;
  device_os: number[] | null;
  banner_placement: number[] | null;
  filters: {
    partner: TransferOfferFilter;
    network: TransferOfferFilter;
    country: TransferOfferFilter;
    [key: string]: TransferOfferFilter;
  };
  created_at: string;
  updated_at: string;
};

export type SystemSetting = {
  id: string;
  value: string;
};

export type MarketingFlow = {
  id: string;
  hash: string;
  segment: string;
  tds_path_id: number;
  description: string;
  created_at: string;
  updated_at: string;
};

export type TdsPath = {
  id: string;
  name: string;
  host: string;
  direction: string;
  landing_number: number;
  destination_path: string;
  created_at: string;
  updated_at: string;
};

export type TextCategory = {
  id: string;
  name: string;
  parent_id: number;
  created_at: string;
  updated_at: string;
  level: number;
};
export type TextSource = {
  id: string;
  key: string;
  value: string;
  category_id: number;
  created_at: string;
  updated_at: string;
};

export type Creative = {
  id: number;
  name: string;
  countries: [];
  placement: number;
  is_active: boolean;
  is_native: boolean;
  platforms: [];
  group_ids: number[];
  sites: number[];
  comment: string;
  type: string | null;
  creative: string;
  created_at: string;
  updated_at: string;
};

export const textTranslationsActions = [
  { id: 'disapprove', name: 'Disapprove' },
  { id: 'retranslate', name: 'Retranslate' },
  { id: 'keep', name: 'Keep (do nothing)' },
];

export const costModelChoices = [
  { id: null, name: '< Choose one >' },
  { id: 'cpc', name: 'cpc' },
  { id: 'cpm', name: 'cpm' },
  { id: 'cpl', name: 'cpl' },
  { id: 'cps', name: 'cps' },
  { id: 'revshare', name: 'revshare' },
  { id: 'flatrate', name: 'flatrate' },
];

export const bannerPlacementChoices = [
  { id: 1, name: 'finder horizontal' },
  { id: 2, name: 'finder vertical' },
  { id: 3, name: 'threads' },
  { id: 4, name: 'chat' },
  { id: 5, name: 'interstitial' },
  { id: 6, name: 'click under' },
  { id: 7, name: 'popunder' },
  { id: 8, name: 'floating bubble' },
  { id: 9, name: 'ad video popup' },
  { id: 10, name: 'pic in pic' },
  { id: 11, name: 'notifications horizontal' },
  { id: 12, name: 'chat fml' },
  { id: 13, name: 'partner offer' },
  { id: 14, name: 'redirect' },
  { id: 15, name: 'session fml' },
  { id: 16, name: 'iframe payment' },
  { id: 17, name: 'submit redirect' },
];

export const siteGroupIdChoices = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
  { id: 6, name: '6' },
];

export const siteIdChoices = [
  { id: 1, name: 'hookupers.com' },
  { id: 2, name: 'besthookuper.com' },
  { id: 3, name: 'hookupparadise.com' },
  { id: 5, name: 'meplusyou.club' },
  { id: 16, name: 'locals-date.com' },
  { id: 17, name: 'lust-station.com' },
  { id: 18, name: 'localsjoy.com' },
  { id: 4, name: 'dates-me.com' },
];

export const comparisonOperators = {
  equal: '=',
  not_equal: '!=',
  less: '<',
  more: '>',
  less_equal: '<=',
  more_equal: '>=',
};

export const conditionOperators = {
  in: 'IN',
  not_in: 'NOT IN',
  like: 'LIKE',
  not_like: 'NOT LIKE',
  is_null: 'IS NULL',
  is_not_null: 'IS NOT NULL',
};

export const paymentMethodChoices = [
  { id: 1, name: 'Solid' },
  { id: 2, name: 'Brilliant pay' },
];
