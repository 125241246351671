export const bannerPlacementChoices = [
  { id: 1, name: 'finder horizontal' },
  { id: 2, name: 'finder vertical' },
  { id: 3, name: 'threads' },
  { id: 4, name: 'chat' },
  { id: 11, name: 'notifications horizontal' },
];

export const platformChoices = [
  { id: 3, name: 'mobile' },
  { id: 1, name: 'desktop' },
  { id: 2, name: 'tablet' },
  { id: null, name: 'all' },
];
