import { httpClient } from 'httpClient';

import { IChoice, EnumResponse } from 'types/global';

const createFetchChoices = (
  endpoint: string,
  transform: (data: EnumResponse) => IChoice[],
) => {
  return async (): Promise<IChoice[]> => {
    try {
      const { data } = await httpClient.get<EnumResponse>(`/enum/${endpoint}`);

      return transform(data);
    } catch (error) {
      console.error(`Error fetching ${endpoint} choices:`, error);
      throw error;
    }
  };
};

const transformKeysToChoices = (data: EnumResponse): IChoice[] =>
  Object.keys(data).map((key) => ({ id: key, name: key }));

const transformEntriesToChoices = (data: EnumResponse): IChoice[] =>
  Object.entries(data).map(([id, name]) => ({
    id: Number(id),
    name: name.toLowerCase(),
  }));

export const ChoicesApi = {
  fetchTrialChoices: createFetchChoices('trial', transformKeysToChoices),
  fetchPaymentFormDesignChoices: createFetchChoices(
    'payment-form-design',
    transformKeysToChoices,
  ),
  fetchPackageTypeChoices: createFetchChoices(
    'package-type',
    transformKeysToChoices,
  ),
  fetchCurrencyChoices: createFetchChoices('currency', transformKeysToChoices),
  fetchPackageScopeChoices: createFetchChoices(
    'package-scope',
    transformKeysToChoices,
  ),
  fetchTransactionTypeChoices: createFetchChoices(
    'transaction-type',
    transformKeysToChoices,
  ),

  fetchDisplayPriceTypeChoices: createFetchChoices(
    'display-price-type',
    transformEntriesToChoices,
  ),
  fetchProductTypeChoices: createFetchChoices(
    'type-product',
    transformEntriesToChoices,
  ),
  fetchProductSubtypeChoices: createFetchChoices(
    'subtype-product',
    transformEntriesToChoices,
  ),
  fetchRetryLogicChoices: createFetchChoices(
    'retry-logic',
    transformEntriesToChoices,
  ),
  fetchDiscountTypeChoices: createFetchChoices(
    'discount-type',
    transformEntriesToChoices,
  ),
};
